<template>
  <div class="k-9sptci">
    <div style="display: flex; flex-direction: row">
      <div
        style="height: 80px; display: flex; flex-direction: column; justify-content: center; margin: auto 20px"
      >
        <div style="font-weight: bold; font-size: 20px; text-align: center">
          {{ summary.name }}
        </div>
        <div style="text-align: center; margin-top: 5px; font-size: 14px">
          {{ summary.id }}
        </div>
      </div>
      <div
        style="height: 80px; border:1px solid rgb(0, 140, 201); border-radius: 10px; display: flex; flex-direction: column; justify-content: center;margin: auto 20px"
      >
        <div
          style="display: flex; flex-direction: row; color: rgb(0, 140, 201)"
        >
          <div style="border-right:1px solid rgb(0, 140, 201); padding: 0 20px">
            本期最佳
          </div>
          <div
            style="min-width: 200px; max-width: 300px; width: fit-content; margin: auto 20px; white-space: normal; word-break: break-all"
          >
            {{ summary.best }}
          </div>
        </div>
      </div>

      <div
        style="height: 80px;background-color: rgba(255, 0, 0, 0.4); border-radius: 10px; padding: 0 10px; width: fit-content; min-width: 100px; margin: auto 20px"
      >
        <div
          style="color: #ffffff; font-weight: bold; font-size: 14px;padding: 10px 10px"
        >
          投稿总数
        </div>
        <div
          style="color: #ffffff; font-weight: bold; font-size: 18px; width: 100%; text-align: center; white-space: nowrap"
        >
          {{ summary.submission_count }}
        </div>
      </div>

      <div
        style="height: 80px;background-color: rgba(255, 153, 0, 0.71); border-radius: 10px; padding: 0 10px; width: fit-content; min-width: 100px; margin: auto 20px"
      >
        <div
          style="color: #ffffff; font-weight: bold; font-size: 14px;padding: 10px 10px"
        >
          投票总数
        </div>
        <div
          style="color: #ffffff; font-weight: bold; font-size: 18px; width: 100%; text-align: center; white-space: nowrap"
        >
          {{ summary.fan_count }}
        </div>
      </div>

      <div
        style="height: 80px;background-color: rgba(35, 180, 0, 0.46); border-radius: 10px; padding: 0 10px; width: fit-content; min-width: 100px; margin: auto 20px"
      >
        <div
          style="color: #ffffff; font-weight: bold; font-size: 14px;padding: 10px 10px"
        >
          评论总数
        </div>
        <div
          style="color: #ffffff; font-weight: bold; font-size: 18px; width: 100%; text-align: center; white-space: nowrap"
        >
          {{ summary.comment_count }}
        </div>
      </div>
    </div>
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-form-item>
      <el-form-item label="关键字" prop="id" v-if="false">
        <el-input
          placeholder="关键字"
          v-model="searchForm.key"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="投稿ID" prop="id">
        <el-input
          placeholder="投稿ID"
          v-model="searchForm.id"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="作者昵称" prop="author">
        <el-input
          placeholder="作者昵称"
          v-model="searchForm.author"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="作者UID" prop="uid">
        <el-input
          placeholder="作者UID"
          v-model="searchForm.uid"
          style="width: 150px"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          v-model="searchForm.status"
          clearable
          placeholder="请选择"
          style="width: 120px"
        >
          <el-option value="" label="全部"></el-option>
          <el-option value="0" label="待审核"></el-option>
          <el-option value="1" label="已通过"></el-option>
          <el-option value="2" label="已拒绝"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="提交时间"
        prop="search_time"
        :rules="[
          {
            validator: searchTimeValidator,
          },
        ]"
      >
        <div style="display: flex; flex-direction: row">
          <el-date-picker
            v-model="searchForm.start"
            type="date"
            placeholder="选择日期"
            style="width: 150px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="searchForm.end"
            type="date"
            placeholder="选择日期"
            style="width: 150px"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="handleQuery(true)">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="submissionTable"
      :data="dataTable"
      style="margin-top:20px"
      border
      @row-click="openView"
    >
      <el-table-column width="55">
        <template slot-scope="scope">
          <el-checkbox
            @click.native.stop="handleSelectionChange(scope.row, $event)"
            v-model="scope.row.checked"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in dataList"
        :key="item._id"
        :prop="item.prop"
        :type="item.type"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="350">
        <template slot-scope="scope">
          <perButton
            perm="activity:save_submission"
            @click.native.stop="handleEdit(scope.row)"
            >编辑</perButton
          >
          <perButton
            perm="activity:review_submission"
            @click.native.stop="handleReview(scope.row)"
            >审核</perButton
          >
          <perButton
            perm="activity:set_submission_fan"
            @click.native.stop="handleOpenFan(scope.row)"
            >点赞管理</perButton
          >
          <perButton
            perm="activity:set_comment_show"
            @click.native.stop="handleCommentManager(scope.row)"
            >评论管理</perButton
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        @current-change="handleQuery()"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog title="点赞管理" :visible.sync="fanVisible">
      <el-form
        ref="fanForm"
        :model="fanParams"
        :rules="fanRules"
        label-width="80px"
      >
        <el-form-item label="投稿">
          <div>{{ fanParams.name }}</div>
        </el-form-item>
        <el-form-item label="票数" prop="count">
          <el-input
            v-model="fanParams.count"
            placeholder="请输入票数"
            maxlength="10"
            type="number"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changeFan">确定</el-button>
          <el-button @click="fanVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import perButton from "@/components/perm/perButton";
export default {
  components: {
    perButton,
  },
  data() {
    return {
      _id: "",
      pageSize: 10,
      fanParams: {
        _id: "",
        name: "",
        count: 0,
      },
      fanVisible: false,
      fanRules: {
        count: [
          { required: true, message: "请输入票数", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (isNaN(Number.parseInt(value))) {
                callback(new Error("请输入大于等于0的整数"));
              }

              if (Number.isInteger(Number(value)) && Number(value) > -1) {
                callback();
              } else {
                callback(new Error("请输入大于等于0的整数"));
              }
            },
            trigger: "blur",
          },
        ],
      },
      summary: {
        name: "",
        id: "",
        best: "",
        submission_count: "",
        fan_count: "",
        comment_count: "",
      },
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        key: "",
        id: "",
        name: "",
        author: "",
        uid: "",
        start: "",
        end: "",
        status: "",
      },
      parentTableData: [],
      tableSelectedRows: false,
      dataTable: [],
      dataList: [
        {
          prop: "id",
          label: "稿子ID",
        },
        {
          prop: "name",
          label: "板子名",
        },
        {
          prop: "author",
          label: "作者",
        },
        {
          prop: "contact_info",
          label: "联系方式",
        },
        {
          prop: "updateTime",
          label: "提交时间",
          formatter: this.timeFormat,
        },
        {
          prop: "status",
          label: "状态",
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "待审核";
              case 1:
                return "已通过";
              case 2:
                return "已拒绝";
              default:
                return "";
            }
          },
        },
        {
          prop: "fan_count",
          label: "点赞数",
          width: 100,
        },
        {
          prop: "comment_count",
          label: "评论数",
          width: 100,
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this._id = this.$route.query._id;
    this.bindData();
    this.$bus.$off("onSubmissionChanged", this.onSubmissionChanged);
    this.$bus.$on("onSubmissionChanged", this.onSubmissionChanged);
    this.$bus.$off("onBestSubmisstionChanged", this.onBestSubmisstionChanged);
    this.$bus.$on("onBestSubmisstionChanged", this.onBestSubmisstionChanged);
  },
  unmounted() {
    this.$bus.$off("onSubmissionChanged", this.onSubmissionChanged);
    this.$bus.$off("onBestSubmisstionChanged", this.onBestSubmisstionChanged);
  },
  destroyed() {
    this.$bus.$off("onSubmissionChanged", this.onSubmissionChanged);
    this.$bus.$off("onBestSubmisstionChanged", this.onBestSubmisstionChanged);
  },
  methods: {
    searchTimeValidator(rule, value, callback) {
      if (
        this.searchForm.start &&
        this.searchForm.start != "" &&
        this.searchForm.end &&
        this.searchForm.end != ""
      ) {
        let end = new Date(this.searchForm.end);
        let start = new Date(this.searchForm.start);
        if (start > end) {
          callback(new Error("截至时间必须大于等于起始时间"));
          return;
        }
      }

      callback();
    },
    onBestSubmisstionChanged(data) {
      if (this._id == data._id) {
        this.summary.best = data.best.join(",");
      }
    },
    onSubmissionChanged() {
      this.handleQuery(false).then();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery(false);
    },
    async bindData() {
      this.loading = true;
      await this.bindSummary();
      this.loading = false;
      await this.handleQuery(true);
    },
    async bindSummary() {
      let { data, errorCode } = await this.$http.submissionSummary(this._id);
      this.summary = {
        ...data,
        best: data.best.join(","),
      };
    },
    handleSelectionChange(row, $event) {
      if ($event.target.tagName !== "INPUT") return;
      let index = this.parentTableData.indexOf(row._id);
      if (index >= 0) {
        this.parentTableData.splice(index, 1);
      } else {
        this.parentTableData.push(row._id);
      }
    },
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format("yyyy/MM/dd hh:mm:ss");
      } else {
        return cellValue;
      }
    },
    handleEdit(row) {
      this.removeTabByPath("/activities/edit_submission");
      this.removeTabByPath("/activities/view_submission");
      this.removeTabByPath("/activities/review_submission");
      this.$router.push({
        path: "/activities/edit_submission",
        query: { act_id: this._id, _id: row._id },
      });
    },
    handleReview(row) {
      this.removeTabByPath("/activities/edit_submission");
      this.removeTabByPath("/activities/view_submission");
      this.$router.push({
        path: "/activities/review_submission",
        query: { act_id: this._id, _id: row._id },
      });
    },
    openView(row) {
      this.removeTabByPath("/activities/edit_submission");
      this.removeTabByPath("/activities/review_submission");
      this.$router.push({
        path: "/activities/view_submission",
        query: { act_id: this._id, _id: row._id },
      });
    },
    handleCommentManager(row) {
      this.removeTabByPath("/activities/comment_manager");
      this.$router.push({
        path: "/activities/comment_manager",
        query: { act_id: this._id, _id: row._id },
      });
    },
    async handleQuery(ref) {
      if (ref == true) {
        this.$refs.searchForm.validate((v) => {});
        this.parentTableData = [];
        this.pager.pageNum = 0;
        this.pager.total = 0;
      }
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.submissionList({
        _id: this._id,
        ...this.searchForm,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      data.data.forEach((r) => {
        r.checked = this.parentTableData.includes(r._id);
      });
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    handleOpenFan(row) {
      this.fanParams._id = row._id;
      this.fanParams.name = row.name;
      this.fanParams.count = row.fan_count;
      this.fanVisible = true;
    },
    async changeFan() {
      this.$refs.fanForm.validate(async (valid) => {
        if (valid) {
          let { data, errorCode } = await this.$http.setSubmissionFan({
            _id: this.fanParams._id,
            act_id: this._id,
            count: this.fanParams.count + "",
          });

          if (errorCode != "0000") {
            this.$message.error("设置失败");
            return;
          }

          this.$message({
            type: "success",
            message: "设置成功",
          });

          this.fanVisible = false;
          let curRow = this.dataTable.find((r) => r._id == this.fanParams._id);
          if (curRow) {
            curRow.fan_count = data.fan_count;
          }

          await this.bindSummary();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async handleExport() {
      if (!this.parentTableData.length) {
        this.$message.error("请选择要导出的数据");
        return;
      }

      this.$http.download({
        url: "/admin/activity/export_submission",
        method: "post",
        data: { act_id: this._id, _ids: this.parentTableData },
      });
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>
<style lang="less" src="../less/user.less"></style>
